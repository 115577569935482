// Header component.
#header {
    height: rem(100);
    display: flex;
    flex-direction: row;

    >div,
    .left,
    .right {
        height: 100%;
    }

    .left,
    .right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    // This is where the logo goes.
    .left {
        margin-left: 2rem;

        a {
            display: block;
            background-image: url('../../../../public/noomero-logo/letter-n-256.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            width: rem(70);
            height: rem(70);

            span {
                @include offScreen();
            }
        }
    }

    .right {
        margin-right: 2rem;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: right;
    }

    // Default (small).
    @media screen and (min-width: rem($bp-sm)) {}

    // Medium.
    // Large.
}