// 
// 
//

html,
body {
    margin: 0;
    padding: 0;
    background-color: white;
}

// Page content.
#page-content-container {
    max-width: 800px;
    width: calc(100vw - 4rem);
    margin: 0 auto;
    // Height.
    // min-height: calc(100vh - 10rem);
}

// Basic container for the Create Number button.
#create-number-container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: white;
    height: rem(80);
    width: 100%;

    >div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}