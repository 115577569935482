// General typography.
html,
body {
    font-size: $base-font-size;
    line-height: 1.25em;
}

div,
p {
    font-size: rem(18);
}