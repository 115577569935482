// Nav styles.
#main-nav {
    display: flex;
    height: 100%;
}

@mixin nav-item {
    font-weight: bold;
    text-decoration: none;
    color: black;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

// This sits within main nav.
.main-nav-pages {
    height: 100%;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul {
        height: 100%;

        >li {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: rem(30);

            >a {
                @include nav-item();
            }
        }
    }
}

// This sits within main nav.
.main-nav-settings {
    height: 100%;

    >div {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: rem(30);

        a {
            @include nav-item();
        }
    }
}