// All basic values.
$base-font-size: 16px;

// Breakpoints.
// X-Small	<576px
$bp-sm: 576;
$bp-md: 768;
$bp-lg: 992;
$bp-xl: 1200;
$bp-xxl: 1400;

//
// Colours
//

// Buttons.
$color-button-text: black;
$color-button-background: white;